<template>
  <BasicCrud
    ref="crud"
    :fields="fields"
    :title="$t('Payments')"
    path="payment"
    :show-new-button="false"
    :priceColumns="['item.value', 'item.qtd']"
    custom-search-event="search"
    @search="searchFor"
    fluid
  >
    <template v-slot:beforesearch>
      <v-switch
        style="margin-top:22px"
        v-model="autoReload"
        :label="$t('Auto reload')"
        @click="toggleAutoReload"
      ></v-switch>
    </template>

    <template v-slot:aftersearch>
      <v-btn @click="download" style="margin-left:10px" icon>
        <v-icon>mdi-download</v-icon>
      </v-btn>
    </template>

    <template v-slot:table>
      <v-data-table
        :headers="headers"
        :items="items"
        class="elevation-0"
        :loading="loading"
        :items-per-page="20"
        :options.sync="options"
        :footer-props="{'items-per-page-options': [10, 20, 30, 40, 50, 100, 200, 300, 400, 500, 1000, 5000]}"
        :server-items-length="itemsLength"
        show-expand
      >

        <template v-for="(pc, i) in priceColumns" v-slot:[pc]="{ item }">
          <span :key="i" v-if="item[pc.replace('item.', '')]">{{ convertPriceValue(item[pc.replace('item.', '')]) | VMask(priceMask) }}</span>
        </template>

        <template v-slot:item.customer="{ item }">
          {{ item.customer.fullname }}<br/>
          <span class="text-caption">{{ item.customer.email }}</span>
          <span v-if="item.customer.mobile" class="text-caption"> - {{ item.customer.mobile }}</span>
        </template>

        <template v-slot:item.actions="{ item }">
          <v-icon
            @click="resendEmail(item.id)"
            v-if="item.status == 'paid'"
          >mdi-email-sync</v-icon>

          <v-icon
            @click="checkPix(item.id)"
            v-if="item.status == 'pending' && item.method == 'pix'"
          >mdi-timer-sync-outline</v-icon>
        </template>

        <template v-slot:expanded-item="{ headers, item }">
          <td :colspan="headers.length" style="padding: 20px">
            <p class="text-center">
              {{ item.message }}
            </p>
            <v-simple-table style="background:transparent" v-if="item.status == 'paid'">
              <template v-slot:default>
                <thead>
                  <tr>
                    <th class="text-left">
                      {{ $t('Student') }}
                    </th>
                    <th class="text-left">
                      {{ $t('Code') }}
                    </th>
                    <th>
                      {{ $t('Student email') }}
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr
                    v-for="voucher in item.vouchers"
                    :key="voucher.code"
                  >
                    <td>
                      {{ voucher.student.fullname }}
                    </td>
                    <td>{{ voucher.code }}</td>
                    <td>{{ voucher.student.email }}</td>
                  </tr>
                </tbody>
              </template>
            </v-simple-table>
          </td>
        </template>
      </v-data-table>
    </template>
  </BasicCrud>
</template>

<script>

import BasicCrud from '@/components/BasicCrud'
import { mapState, mapActions } from 'vuex'
import i18n from '@/plugins/i18n.js'
import api from '@/services/api'
import createNumberMask from 'text-mask-addons/dist/createNumberMask'
import AppActions from '@/store/app/actions-types'
import { DateTime } from "luxon"

export default {
  name: "Payment",

  components: {
    BasicCrud
  },

  watch: {
    options: {
      handler () {
        this.reloadData()
      },
      deep: true,
    },
  },

  computed: {
    ...mapState({
    fields: state => state.payment.fields,
    headers: state => state.payment.headers
    }),
  },

  data() {
    return {
      priceColumns: ['item.price', 'item.amount'],
      items: [],
      options: {sortBy: ['created_at'], sortDesc: ['created_at']},
      itemsLength: 20,
      loading: false,
      search: null,
      autoReload: false,
      reloadDaemon: null,
      priceMask: createNumberMask({
        allowDecimal: true,
        includeThousandsSeparator: true,
        allowNegative: false,
        thousandsSeparatorSymbol: i18n.t('thousandSeparatorSymbol'),
        decimalSymbol: i18n.t('decimalSymbol'),
        prefix: i18n.t('currencyPrefix')
      })
    }
  },

  methods: {
    ...mapActions('app', [
        AppActions.OPEN_APP_ERROR_MESSAGE,
        AppActions.OPEN_APP_SUCCESS_MESSAGE
      ]
    ),

    reloadData() {
      this.loading = true

      let options = {...this.$route.query}

      if (this.options.sortBy && this.options.sortBy.length > 0) {
        options.order = this.options.sortBy[0]
      }

      if (this.options.sortDesc && this.options.sortDesc.length > 0 && this.options.sortDesc[0]) {
        options.direction = 'DESC'
      }

      options.limit = this.options.itemsPerPage
      options.page = this.options.page
      options.search = this.search

      options["filters[e.active]"] = 1

      api
        .find('payment', options)
        .then((response) => {
          this.items = response.data.results
          this.itemsLength = response.data.total
          this.loading = false
        })
        .catch(() => this.loading = false)
    },

    convertPriceValue(price) {
      if (!price) {
        return price;
      }

      return price
        .toString()
        .replace('.', i18n.t("decimalSymbol"))
    },

    searchFor(search) {
      this.search = search
      this.reloadData()
    },

    resendEmail(paymentId) {
      this.loading = true
      api.getOne('payment/resend', paymentId)
        .then(() => {
          this.loading = false
          this[AppActions.OPEN_APP_SUCCESS_MESSAGE](this.$t('Success on send emails.'))
        })
        .catch(() => {
          this.loading = false
          this[AppActions.OPEN_APP_ERROR_MESSAGE](this.$t('Error on send emails.'))
        })
    },

    checkPix(paymentId) {
      this.loading = true
      api.getOne('payment/pix/check', paymentId)
        .then(() => {
          this.reloadData()
          this[AppActions.OPEN_APP_SUCCESS_MESSAGE](this.$t('Success on check PIX.'))
        })
        .catch(() => {
          this.loading = false
        })
    },

    download() {
      let headers = [
        this.$t('ID'),
        this.$t('Customer'),
        this.$t('Customer') + ' ' + this.$t('email'),
        this.$t('Customer') + ' CPF',
        this.$t('Mobile'),
        this.$t('Created at'),
        this.$t('Method'),
        this.$t('Status'),
        this.$t('Price'),
        this.$t('Unit'),
        this.$t('Product'),
        this.$t('Event'),
        this.$t('Qtd'),
        this.$t('Amount'),
        this.$t('Transaction ID')
      ]

      let items = []

      this.items.forEach((item) => {
        let itemValues = [
          item.id,
          item.customer.fullname,
          item.customer.email,
          item.customer.cpf,
          item.customer.mobile,
          this.parseDateValue(item.created_at),
          item.method,
          item.status,
          item.price,
          item.event.unit.name,
          item.event.product.title,
          this.parseDateValue(item.event.start_at) + ' - ' + this.parseDateValue(item.event.finish_at),
          item.qtd,
          item.amount,
          item.transaction_id
        ]

        items.push(itemValues)
      })

      this.exportCSVFile(headers, items, 'payment_report')
    },

    exportCSVFile(headers, items, fileTitle) {
      if (headers) {
          items.unshift(headers)
      }

      let jsonObject = JSON.stringify(items)
      
      let csv = this.convertToCSV(jsonObject)
      
      let exportedFilename = fileTitle + '.csv'
      
      let blob = new Blob([csv], { type: 'text/csv;charset=utf-8;' })

      if (navigator.msSaveBlob) {
        navigator.msSaveBlob(blob, exportedFilename)
        return
      }

      var link = document.createElement("a");

      if (link.download !== undefined) {
        var url = URL.createObjectURL(blob)

        link.setAttribute("href", url)
        link.setAttribute("download", exportedFilename)
        link.style.visibility = 'hidden'

        document.body.appendChild(link)

        link.click()

        document.body.removeChild(link)
      }
    },

    convertToCSV(objArray) {
        var array = typeof objArray != 'object' ? JSON.parse(objArray) : objArray
        var str = ''

        for (var i = 0; i < array.length; i++) {
            var line = ''
            for (var index in array[i]) {
                if (line != '') line += ','

                line += array[i][index]
            }

            str += line + '\r\n'
        }

        return str;
    },

    parseDateValue(dateValue) {
      return DateTime.fromISO(dateValue.replace(' ', 'T')).setLocale(i18n.locale).toLocaleString()
    },

    toggleAutoReload() {
      if (this.autoReload && !this.reloadDaemon) {
        this.reloadDaemon = window.setInterval(() => {this.autoReload && this.reloadData()}, 5000)
      }

      if (!this.autoReload && this.reloadDaemon) {
        window.clearInterval(this.reloadDaemon)
        this.reloadDaemon = null
      }
    }
  }
}

</script>
