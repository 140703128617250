var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('BasicCrud',{ref:"crud",attrs:{"fields":_vm.fields,"title":_vm.$t('Payments'),"path":"payment","show-new-button":false,"priceColumns":['item.value', 'item.qtd'],"custom-search-event":"search","fluid":""},on:{"search":_vm.searchFor},scopedSlots:_vm._u([{key:"beforesearch",fn:function(){return [_c('v-switch',{staticStyle:{"margin-top":"22px"},attrs:{"label":_vm.$t('Auto reload')},on:{"click":_vm.toggleAutoReload},model:{value:(_vm.autoReload),callback:function ($$v) {_vm.autoReload=$$v},expression:"autoReload"}})]},proxy:true},{key:"aftersearch",fn:function(){return [_c('v-btn',{staticStyle:{"margin-left":"10px"},attrs:{"icon":""},on:{"click":_vm.download}},[_c('v-icon',[_vm._v("mdi-download")])],1)]},proxy:true},{key:"table",fn:function(){return [_c('v-data-table',{staticClass:"elevation-0",attrs:{"headers":_vm.headers,"items":_vm.items,"loading":_vm.loading,"items-per-page":20,"options":_vm.options,"footer-props":{'items-per-page-options': [10, 20, 30, 40, 50, 100, 200, 300, 400, 500, 1000, 5000]},"server-items-length":_vm.itemsLength,"show-expand":""},on:{"update:options":function($event){_vm.options=$event}},scopedSlots:_vm._u([_vm._l((_vm.priceColumns),function(pc,i){return {key:pc,fn:function(ref){
var item = ref.item;
return [(item[pc.replace('item.', '')])?_c('span',{key:i},[_vm._v(_vm._s(_vm._f("VMask")(_vm.convertPriceValue(item[pc.replace('item.', '')]),_vm.priceMask)))]):_vm._e()]}}}),{key:"item.customer",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.customer.fullname)),_c('br'),_c('span',{staticClass:"text-caption"},[_vm._v(_vm._s(item.customer.email))]),(item.customer.mobile)?_c('span',{staticClass:"text-caption"},[_vm._v(" - "+_vm._s(item.customer.mobile))]):_vm._e()]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [(item.status == 'paid')?_c('v-icon',{on:{"click":function($event){return _vm.resendEmail(item.id)}}},[_vm._v("mdi-email-sync")]):_vm._e(),(item.status == 'pending' && item.method == 'pix')?_c('v-icon',{on:{"click":function($event){return _vm.checkPix(item.id)}}},[_vm._v("mdi-timer-sync-outline")]):_vm._e()]}},{key:"expanded-item",fn:function(ref){
var headers = ref.headers;
var item = ref.item;
return [_c('td',{staticStyle:{"padding":"20px"},attrs:{"colspan":headers.length}},[_c('p',{staticClass:"text-center"},[_vm._v(" "+_vm._s(item.message)+" ")]),(item.status == 'paid')?_c('v-simple-table',{staticStyle:{"background":"transparent"},scopedSlots:_vm._u([{key:"default",fn:function(){return [_c('thead',[_c('tr',[_c('th',{staticClass:"text-left"},[_vm._v(" "+_vm._s(_vm.$t('Student'))+" ")]),_c('th',{staticClass:"text-left"},[_vm._v(" "+_vm._s(_vm.$t('Code'))+" ")]),_c('th',[_vm._v(" "+_vm._s(_vm.$t('Student email'))+" ")])])]),_c('tbody',_vm._l((item.vouchers),function(voucher){return _c('tr',{key:voucher.code},[_c('td',[_vm._v(" "+_vm._s(voucher.student.fullname)+" ")]),_c('td',[_vm._v(_vm._s(voucher.code))]),_c('td',[_vm._v(_vm._s(voucher.student.email))])])}),0)]},proxy:true}],null,true)}):_vm._e()],1)]}}],null,true)})]},proxy:true}])})}
var staticRenderFns = []

export { render, staticRenderFns }